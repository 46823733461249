<template>
  <v-dialog v-model="showModal" max-width="90%">

    <template v-slot:activator="{ attrs, on }">
      <slot name="activator" v-bind="{on, attrs}">
        <v-btn v-on="on" v-bind:attrs="attrs" color="primary" small>Créer</v-btn>
      </slot>
    </template>

    <v-card>

      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon @click="reset">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <vue-scroll>

        <v-card-text style="max-height: 70vh" class="mb-4">
          <div>
            <span class="subtitle-2">Audience (EntityID)</span>
            <br>
            <code-snippet
                :code="'https://' + (creatingConfiguration.client ? creatingConfiguration.client : '[URL]') + '/planete-online-server/authentication/saml2/metadata.xml'"
                enable-copy
            ></code-snippet>
          </div>

          <div class="my-2">
            <span class="subtitle-2">Callback URL (ACS Endpoint)</span>
            <br>
            <code-snippet
                :code="'https://' + (creatingConfiguration.client ? creatingConfiguration.client : '[URL]') + '/planete-online-server/authentication/saml2/login/callback'"
                enable-copy
            ></code-snippet>
          </div>

          <v-divider class="my-2"></v-divider>

          <v-text-field
              v-model="creatingConfiguration.title"
              label="Titre de la configuration"
              :error-messages="errors.title"
          ></v-text-field>

          <div class="d-flex flex-row align-start justify-space-between">
            <div class="d-flex flex-column align-stretch justify-space-between" style="width: 48%">

              <client-picker-select
                  v-model="creatingConfiguration.client"
                  :alreadyExists="alreadyExists"
              ></client-picker-select>

              <v-select
                  v-model="creatingConfiguration.authProtocol"
                  :items="ssoProtocols"
                  item-text="name"
                  return-object
                  label="Protocole SSO"
              ></v-select>


              <v-checkbox
                  v-model="creatingConfiguration.loginButtonVisible"
                  @click="checkssovisible('loginButtonVisible')"
                  label="Bouton de connexion SSO visible"
              ></v-checkbox>

              <v-text-field
                  v-model="creatingConfiguration.idpLinkData"
                  label="Donnée reçue par l'Identity Provider"
              ></v-text-field>

              <v-text-field
                  v-model="creatingConfiguration.spLinkData"
                  label="Donnée traitée par le Service Provider"
              ></v-text-field>

          <!-- <div class="my-2"> -->
              <v-checkbox
                v-model="creatingConfiguration.onlySso"
                @click="checkssovisible('onlySso')"
                label="Connexion SSO uniquement"
              ></v-checkbox>
          <!-- </div>
          <div class="my-2"> -->
              <v-checkbox
                v-model="creatingConfiguration.forceSso"
                @click="checkssovisible('forceSso')"
                label="Forcer connexion SSO si disponible"
              ></v-checkbox>
          <!-- </div> -->
            </div>
            <v-divider vertical></v-divider>
            <div class="d-flex flex-column align-stretch justify-space-between" style="width: 48%">

              <span class="d-flex flex-column align-center subtitle-2">Configuration des metadatas</span>

              <auth-data-form
                  v-model="creatingConfiguration.authDatas"
                  :protocol="creatingConfiguration.authProtocol.name"
              ></auth-data-form>

            </div>
          </div>

        </v-card-text>

      </vue-scroll>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="reset">Annuler</v-btn>
        <v-btn :color="actionColor ? actionColor : 'success'" @click="save">{{ actionLabel || "Créer"}}</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateForm",
  components: {
    ClientPickerSelect: () => import("@/Components/Commons/Inputs/Client/ClientPickerSelect"),
    AuthDataForm: () => import("@/Components/Views/SupportVision/AuthenticationConfiguration/AuthDatasForm"),
    CodeSnippet: () => import("@/Components/Commons/UiElements/CodeSnippet")
  },
  props: {
    value: [Array, Object],
    actionLabel: String,
    actionColor: String,
    title: String,
    alreadyExists: Array
  },
  data() {
    return {
      ssoProtocols: [
        {
          name: "Aucun",
          disabled: false
        },
        {
          name: "OAUTH",
          disabled: true
        },
        {
          name: "SAML",
          disabled: true
        },
        {
          name: "SAML2",
          disabled: false
        }
      ],
      showModal: true,
      creatingConfiguration: {},
      errors: {
        title: "",
      }
    }
  },
  methods: {
    reset() {
      this.creatingConfiguration = {
        title: "",
        client: "",
        active: false,
        idpLinkData: "",
        spLinkData: "",
        authProtocol: {
          name: "Aucun",
          disabled: false
        },
        loginButtonVisible: false,
        onlySso: false,
        authDatas: {}
      }
      this.showModal = false
    },
    checkssovisible(el) {
      if (el=="onlySso") {
        if (this.creatingConfiguration.onlySso) this.creatingConfiguration.loginButtonVisible=true
      }
      else {
        if (!this.creatingConfiguration.loginButtonVisible) this.creatingConfiguration.onlySso=false
      }
    },
    save() {
      let error = false
      if (!this.creatingConfiguration.title) {
        this.errors.title = "Champ obligatoire"
        error = true
      }
      if (!error) {
        this.$emit('input', this.creatingConfiguration)
        this.$emit('save', this.creatingConfiguration)
        this.reset()
      }
    }
  },
  created() {
    this.reset()
  },
  watch: {
    showModal(nValue) {
      if (nValue)
        this.creatingConfiguration = this.value
    }
  }
}
</script>

<style scoped>

</style>